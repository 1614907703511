import React, { useState, useEffect } from "react";
import SearchResultsTemplate from "../../templates/property-results";

export default function SearchResults (props) {

   
    return (
        <React.Fragment>
            <SearchResultsTemplate
                location_path = {props.location.pathname}
            />
        </React.Fragment>
    )
}

// export const Head = (props) => {
//     //the current page data are lives here
//     const page_url_data = parseResultsUrl(props.location)

//     // you can make default search page results filters here
//     const myalgoliafilters = generateFiltersfromPageData(page_url_data['departmentVal'],page_url_data['searchtypeVal'],page_url_data['areaVal'],page_url_data['bedVal'],page_url_data['minpriceVal'],page_url_data['maxpriceVal'],page_url_data['buildingval'])

//     const { pagetitle, pagemetadesc } = useSearchResultsSEO(page_url_data['searchtypeVal'], page_url_data['areaVal'], page_url_data['buildingval'], page_url_data['bedVal'], page_url_data['minpriceVal'], page_url_data['maxpriceVal'])

//     return(
//         <SEOSEARCHRESULTS title={pagetitle} description={pagemetadesc} url={props.location.pathname} />
//     )
// }
