import React from "react";
import loadable from "@loadable/component";

import Layout from "../components/layout";

const SearchResults = loadable(() => import("../components/SearchResults"));

const PropertiesPage = (props) => {

    const sortby_options = [
        { value: '', label: 'Most Recent' },
        { value: 'price-desc', label: 'Highest Price' },
        { value: 'price-asc', label: 'Lowest Price' }
    ];

    return (
        <Layout
            layout={"sticky-module"}
        >
            <div className="layout-padding-top"></div>

            <SearchResults {...props} />
        </Layout>
    )
}

export default PropertiesPage